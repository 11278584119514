import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "controller/context/alertContext";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  deleteCategory,
  updateCategory,
  addCategory,
  UpdateOneCategoryStatus,
} from "controller/services/categoryServices";
import { useOneCategory, useCategoriesList } from "model/queryCalls/queryCalls";

interface Category {
  _id: string;
  name: string;
  description: string;
  isActive: boolean;
}

interface CategoriesResponse {
  data: Category[];
  totalDocumentCount: number;
}

export const useCategories = () => {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState<CategoriesResponse>({
    data: [],
    totalDocumentCount: 0,
  });

  const [categoryImage, setCategoryImage] = useState<{
    name: string;
    url: string;
  } | null>(null);
  const imageInputRef = React.useRef<HTMLInputElement>(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    isActive: true,
    categoryImage: "",
  });

  const handleResetImage = (
    resetImageFn: () => void,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setCategoryImage(null);
    setFieldValue("categoryimage", null);
    resetImageFn();
  };
  const navigate = useNavigate();
  const {
    data: fetchedCategoriesData,
    isSuccess,
    status,
    isRefetching,
    refetch,
  } = useCategoriesList({
    page,
    rowsPerPage,
  });

  useEffect(() => {
    if (isSuccess && fetchedCategoriesData) {
      setCategoriesData(fetchedCategoriesData);
    }
  }, [isSuccess, fetchedCategoriesData, isRefetching]);

  const { data: categoryData, isLoading: isCategoryLoading } = useOneCategory({
    id,
  });

  useEffect(() => {
    if (id && categoryData) {
      setInitialValues({
        name: categoryData.name || "",
        description: categoryData.description || "",
        isActive:
          categoryData.isActive !== undefined ? categoryData.isActive : true,
        categoryImage: categoryData.image?.data?.url || null,
      });
      setCategoryImage({
        name: categoryData.name,
        url: categoryData?.image?.data?.url,
      });
    }
  }, [id, categoryData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const onDelete = async (deleteId: string) => {
    try {
      await deleteCategory({ id: deleteId });

      const updatedCategories = categoriesData?.data?.filter(
        (category) => category._id !== deleteId
      );
      setCategoriesData({
        ...categoriesData,
        data: updatedCategories,
        totalDocumentCount: categoriesData.totalDocumentCount - 1,
      });

      setAlert({
        active: true,
        type: "success",
        message: "Category Deleted successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete",
      });
    }
  };

  const onToggleActive = async (categoryId: string, newIsActive: boolean) => {
    try {
      await UpdateOneCategoryStatus({
        id: categoryId,
        isActive: !newIsActive,
      });
      refetch();

      setAlert({
        active: true,
        type: "success",
        message: "Category status updated successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to update category status",
      });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter a category name"),
    description: Yup.string().optional(),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const categoryData = {
        ...values,
        image: values.image,
      };

      if (id) {
        await updateCategory({ id, data: categoryData });
        setAlert({
          active: true,
          type: "success",
          message: "Category Updated successfully",
        });
      } else {
        await addCategory({ data: categoryData });
        setAlert({
          active: true,
          type: "success",
          message: "Category Created successfully",
        });
      }
      setTimeout(() => {
        navigate("/admin/all-categories");
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to save category",
      });
    }
  };

  return {
    page,
    rowsPerPage,
    navigate,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    onToggleActive,
    categoriesData,
    loading,
    setLoading,
    initialValues,
    setInitialValues,
    validationSchema,
    onSubmit,
    id,
    categoryData,
    isSuccess,
    status,
    isCategoryLoading,
    categoryImage,
    imageInputRef,
    handleResetImage,
  };
};
