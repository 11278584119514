import Card from "components/card";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { CircularProgress } from "@mui/material";
import { useCuisines } from "./useCuisine";
import { MdClose, MdFileUpload } from "react-icons/md";
import ImageUploader from "components/imageUpload";

const AddCuisines = () => {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    loading,
    id,
    cuisineImage,
    handleResetImage,
  } = useCuisines();

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <Card extra={"w-full h-full p-3"}>
          <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
            <div className="px-5">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className="mb-3">
                      <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                        Cuisine Name*
                      </label>
                      <Field
                        type="text"
                        name="name"
                        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="mb-3">
                      <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                        Description
                      </label>
                      <Field
                        as="textarea"
                        name="description"
                        className="mt-2 flex h-24 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    {/* Image Upload Field */}
                    <div className="mb-3">
                      <label className="ml-3 text-sm font-bold text-navy-700">
                        Image
                      </label>
                      <div className="col-span-5 my-3">
                        <Card extra="w-full h-full p-3">
                          <div className="relative flex h-full w-full items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700">
                            <ImageUploader
                              setFieldValue={setFieldValue}
                              folder="/cuisine-images"
                              uploadButton={
                                <div className="flex h-full w-full flex-col items-center justify-center">
                                  <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                                  <h4 className="text-xl font-bold text-brand-500 dark:text-white">
                                    Upload Image
                                  </h4>
                                  <p className="mt-2 text-sm font-medium text-gray-600">
                                    Click to Upload Image
                                  </p>
                                </div>
                              }
                              loadingIndicator={
                                <CircularProgress className="h-[80px] w-[80px]" />
                              }
                              imageDisplay={(cuisineImage, resetImage) => (
                                <div className="relative h-[120px]">
                                  <img
                                    src={cuisineImage.url}
                                    alt={cuisineImage.name}
                                    className="h-[120px] w-[120px]"
                                  />
                                  <button
                                    className="absolute right-1 top-1"
                                    onClick={() =>
                                      handleResetImage(
                                        resetImage,
                                        setFieldValue
                                      )
                                    }
                                  >
                                    <MdClose className="h-6 w-6" />
                                  </button>
                                </div>
                              )}
                              initialImage={cuisineImage}
                            />
                          </div>
                        </Card>
                      </div>
                    </div>

                    <button
                      className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      type="submit"
                    >
                      {loading ? `Saving...` : id ? `Update` : `Save`}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddCuisines;
