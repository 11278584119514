import React, { useContext, useState, useEffect, useRef } from "react";
import { AlertContext } from "controller/context/alertContext";
import { useNavigate } from "react-router-dom";
import envConfig from "../../../env/env.json";
import * as Yup from "yup";
import {
  useRestaurantValues,
  useOneRestaurant,
  useCuisinesList,
  useCategoriesList,
} from "model/queryCalls/queryCalls";
import {
  AddRestaurantValues,
  deleteRestaurant,
  updateRestaurant,
  UpdateOneRestaurantStatus,
} from "controller/services/restaurantServices";
import { FormValues } from "./restaurant.interface";
import { fetchedOptions } from "./dummyData";

export const useRestaurant = () => {
  const { setAlert } = useContext(AlertContext);
  const [restaurantId, setRestaurantId] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [brandImage, setBrandImage] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [bannerImage, setBannerImage] = useState<string | null>(null);
  const [isBannerUploading, setIsBannerUploading] = useState<boolean>(false);
  const [address, setAddress] = useState<string | null>(null);
  const [isActive, setIsActive] = useState(true);
  const [branchId, setBranchId] = useState<string | null>(null);
  const [location, setLocation] = useState<{ lat: number; lng: number } | null>(
    null
  );
  const logoInputRef = useRef<HTMLInputElement>(null);
  const bannerInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const [pinCode, setPinCode] = useState<string>("");
  const [mapCenterLocation, setMapCenterLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [isMapVisible, setIsMapVisible] = useState<boolean>(false);

  const { data: categoriesData } = useCategoriesList({});
  const { data: cuisinesData } = useCuisinesList({});

  const categoryOptions = categoriesData?.data?.map((category: any) => ({
    value: category._id?.toString() || "undefined-id",
    label: category.name || "Unnamed Category",
  }));

  const cuisineOptions = cuisinesData?.data?.map((cuisine: any) => ({
    value: cuisine._id.toString(),
    label: cuisine.name,
  }));

  const { data: oneRestaurantData, refetch: restaurantRefetch } =
    useOneRestaurant({ id: restaurantId });

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: "",
    description: "",
    type: "",
    isActive: true,
    location: "",
    contactDetails: "",
    username: "",
    password: "",
    paymentStatus: "",
    category: [],
    cuisine: [],
    restaurantIcon: null,
    restaurantBanner: null,
    workingHours: {
      sunday: { open: "", close: "" },
      monday: { open: "", close: "" },
      tuesday: { open: "", close: "" },
      wednesday: { open: "", close: "" },
      thursday: { open: "", close: "" },
      friday: { open: "", close: "" },
      saturday: { open: "", close: "" },
    },
  });

  const getCoordinatesFromPinCode = async (pinCode: string) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${pinCode}&key=${envConfig.MapsAPIKey}`
      );
      const data = await response.json();

      if (data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setMapCenterLocation({ lat: location.lat, lng: location.lng });
        setLocation({ lat: location.lat, lng: location.lng });
        getAddress(location.lat, location.lng);
        setIsMapVisible(true);
      } else {
        setIsMapVisible(false);
        setAlert({
          active: true,
          type: "error",
          message: "Invalid pin code. Please enter a valid one.",
        });
      }
    } catch (error) {
      console.error("Error fetching coordinates from pin code:", error);
      setIsMapVisible(false);
      setAlert({
        active: true,
        type: "error",
        message: "Error fetching location. Please try again later.",
      });
    }
  };

  const getAddress = async (lat: number, lng: number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${envConfig.MapsAPIKey}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        setAddress(data.results[0].formatted_address);
      } else {
        setAddress("Address not found");
      }
    } catch (error) {
      console.error("Error fetching address from coordinates:", error);
    }
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat() || 0;
    const lng = event.latLng?.lng() || 0;
    setLocation({ lat, lng });
    getAddress(lat, lng);
  };

  const handlePinCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPinCode(e.target.value);
  };

  const handlePinCodeBlur = () => {
    if (pinCode) {
      getCoordinatesFromPinCode(pinCode);
    }
  };

  const handlePinCodeKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      getCoordinatesFromPinCode(pinCode);
    }
  };
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const paymentStatusOptions = [
    { value: "Paid", label: "Paid" },
    { value: "Unpaid", label: "Unpaid" },
  ];
  const mapIdsToOptions = (
    ids: string[],
    options: { value: string; label: string }[]
  ) => {
    return ids
      .filter((id) => id !== null && id !== undefined)
      .map((id) => options.find((option) => option.value === id.toString()))
      .filter(Boolean);
  };

  useEffect(() => {
    if (restaurantId && oneRestaurantData) {
      setBranchId(oneRestaurantData?.branchDetails[0]?._id);
      setInitialValues((prevValues) => {
        if (prevValues.name !== oneRestaurantData?.name?.en) {
          const mappedCategories = mapIdsToOptions(
            oneRestaurantData.branchDetails[0]?.category,
            categoryOptions
          );
          const mappedCuisines = mapIdsToOptions(
            oneRestaurantData.branchDetails[0]?.cuisine,
            cuisineOptions
          );

          return {
            name: oneRestaurantData?.name?.en || "",
            description: oneRestaurantData?.description || "",
            type: oneRestaurantData?.branchDetails[0]?.type || "",
            isActive: oneRestaurantData?.isActive || false,
            location: oneRestaurantData?.location?.coordinates || {
              lat: 0,
              lng: 0,
            },
            contactDetails:
              oneRestaurantData?.branchDetails[0]?.contactDetails || "",
            username: oneRestaurantData?.branchDetails[0]?.username || "",
            password: oneRestaurantData?.branchDetails[0]?.password || "",
            paymentStatus:
              oneRestaurantData?.branchDetails[0]?.paymentStatus || "",

            cuisine: mappedCuisines.map((cuisId) => cuisId.value),
            category: mappedCategories.map((catId) => catId.value),
            restaurantIcon: oneRestaurantData?.branchDetails[0]?.logo || null,
            restaurantBanner:
              oneRestaurantData?.branchDetails[0]?.banner || null,
            workingHours: {
              sunday: oneRestaurantData?.branchDetails[0]?.workingHours
                ?.sunday || {
                open: "",
                close: "",
              },
              monday: oneRestaurantData?.branchDetails[0]?.workingHours
                ?.monday || {
                open: "",
                close: "",
              },
              tuesday: oneRestaurantData?.branchDetails[0]?.workingHours
                ?.tuesday || {
                open: "",
                close: "",
              },
              wednesday: oneRestaurantData?.branchDetails[0]?.workingHours
                ?.wednesday || {
                open: "",
                close: "",
              },
              thursday: oneRestaurantData?.branchDetails[0]?.workingHours
                ?.thursday || {
                open: "",
                close: "",
              },
              friday: oneRestaurantData?.branchDetails[0]?.workingHours
                ?.friday || {
                open: "",
                close: "",
              },
              saturday: oneRestaurantData?.branchDetails[0]?.workingHours
                ?.saturday || {
                open: "",
                close: "",
              },
            },
          };
        }
        return prevValues;
      });

      if (oneRestaurantData?.branchDetails[0]?.logo) {
        setBrandImage(
          (prevImage) => prevImage || oneRestaurantData?.branchDetails[0]?.logo
        );
      }

      if (oneRestaurantData?.branchDetails[0]?.banner) {
        setBannerImage(
          (prevBanner) =>
            prevBanner || oneRestaurantData?.branchDetails[0]?.banner
        );
      }

      setLocation((prevLocation) => {
        return prevLocation?.lat !==
          oneRestaurantData?.location?.coordinates?.lat
          ? oneRestaurantData?.location?.coordinates
          : prevLocation;
      });

      setAddress((prevAddress) => {
        return prevAddress !==
          oneRestaurantData?.branchDetails[0]?.location?.address
          ? oneRestaurantData?.branchDetails[0]?.location?.address
          : prevAddress;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId, oneRestaurantData]);

  useEffect(() => {
    restaurantRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId]);

  useEffect(() => {
    const fetchOptions = () => {
      setOptions(fetchedOptions);
    };
    fetchOptions();
  }, []);

  const {
    data: restaurantData,
    status,
    refetch,
  } = useRestaurantValues({
    page,
    rowsPerPage,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setRowsPerPage(+event.target.value);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your restaurant name"),
    description: Yup.string().required("Please enter a description"),
    type: Yup.string().required("Please select your restaurant type"),
    contactDetails: Yup.string().required("Please enter your contact details"),
    username: Yup.string()
      .email("Please enter a valid email address")
      .required("Please enter a valid email"),
    password: Yup.string().required("Please enter a password"),
    paymentStatus: Yup.string().required("Please select a payment status"),
    cuisine: Yup.array().of(Yup.string()).required("Please select a cuisine"),
    category: Yup.array().of(Yup.string()).required("Please select a category"),
  });

  const onSubmit = async (values: FormValues) => {
    // if (!brandImage || !bannerImage) {
    //   setAlert({
    //     active: true,
    //     type: "error",
    //     message: "Please upload the logo and banner before submitting.",
    //   });
    //   return;
    // }

    setLoading(true);
    try {
      const restaurantData = {
        ...values,
        logo: brandImage,
        banner: bannerImage,
      };

      if (restaurantId) {
        const updateRes = await updateRestaurant({
          restaurantId,
          data: restaurantData,
          location: location,
          address: address,
        });
        if (updateRes) {
          setBranchId(updateRes.branchId);
          setAlert({
            active: true,
            type: "success",
            message: "Restaurant updated successfully",
          });
          setLoading(false);
        }
      } else {
        const result = await AddRestaurantValues({
          data: restaurantData,
          location: location,
          address: address,
        });

        if (result) {
          setBranchId(result.insertedId);
          setAlert({
            active: true,
            type: "success",
            message: "Restaurant created successfully",
          });
        }
      }
    } catch (error: any) {
      setAlert({
        active: true,
        type: "error",
        message: error.message || "Failed to submit. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (restaurantId: string) => {
    try {
      const result = await deleteRestaurant(restaurantId);
      if (result?.restaurantDeleteResult?.deletedCount > 0) {
        setAlert({
          active: true,
          type: "success",
          message: "Restaurant deleted successfully",
        });
        refetch();
      } else {
        throw new Error("Failed to delete the restaurant");
      }
    } catch (error) {
      console.error("Delete restaurant error:", error);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete restaurant. Please try again.",
      });
    }
  };

  const onToggleActive = async (restaurantId: string, newIsActive: boolean) => {
    try {
      await UpdateOneRestaurantStatus({
        id: restaurantId,
        isActive: !newIsActive,
      });
      refetch();

      setAlert({
        active: true,
        type: "success",
        message: "Restaurant status updated successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to update restaurant status",
      });
    }
  };

  const onLogoSuccess = (res: any) => {
    setBrandImage(res.url);
    setIsUploading(false);
  };

  const onUploadProgress = () => {
    setIsUploading(true);
  };

  const handleDeleteLogoFile = () => {
    setBrandImage(null);
  };

  const onBannerSuccess = (res: any) => {
    setBannerImage(res.url);
    setIsBannerUploading(false);
  };

  const onBannerUploadProgress = () => {
    setIsBannerUploading(true);
  };

  const handleDeleteBannerFile = () => {
    setBannerImage(null);
  };

  return {
    page,
    rowsPerPage,
    navigate,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    loading,
    setLoading,
    initialValues,
    validationSchema,
    onSubmit,
    options,
    brandImage,
    isUploading,
    bannerImage,
    isBannerUploading,
    onLogoSuccess,
    onBannerSuccess,
    onUploadProgress,
    onBannerUploadProgress,
    handleDeleteLogoFile,
    handleDeleteBannerFile,
    isActive,
    setIsActive,
    logoInputRef,
    bannerInputRef,
    location,
    setLocation,
    branchId,
    restaurantData,
    status,
    setAddress,
    address,
    restaurantId,
    setRestaurantId,
    oneRestaurantData,
    onToggleActive,
    categoryOptions,
    cuisineOptions,
    mapIdsToOptions,
    mapCenterLocation,
    isMapVisible,
    handleMapClick,
    handlePinCodeChange,
    handlePinCodeBlur,
    handlePinCodeKeyDown,
    daysOfWeek,
    pinCode,
    paymentStatusOptions,
  };
};
