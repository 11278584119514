import { useContext, useEffect, useState } from "react";
import { AlertContext } from "controller/context/alertContext";
import {
  addModifier,
  updateModifier,
  deleteModifier,
} from "controller/services/modifierServices";

interface Item {
  _id?: string;
  name: string;
  price: string;
  description: string;
}

interface InitialValues {
  items: Item[];
}

export const useModifiers = (modifiersData: any) => {
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<InitialValues>({
    items: [{ name: "", price: "", description: "" }],
  });

  useEffect(() => {
    if (modifiersData && modifiersData.length > 0) {
      setInitialValues({
        items: modifiersData.map((modifier: any) => ({
          _id: modifier._id || undefined,
          name: modifier.name,
          price: modifier.price,
          description: modifier.description,
        })),
      });
    }
  }, [modifiersData]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      if (values._id) {
        await updateModifier({ id: values._id, data: values });
        setAlert({
          active: true,
          type: "success",
          message: "Modifier updated successfully",
        });
      } else {
        await addModifier({ data: values });
        setAlert({
          active: true,
          type: "success",
          message: "Modifier created successfully",
        });
      }
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to save modifier",
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteModifierFromDB = async (modifierId: string) => {
    setLoading(true);
    try {
      await deleteModifier({ id: modifierId });
      setAlert({
        active: true,
        type: "success",
        message: "Modifier deleted successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete modifier",
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    initialValues,
    onSubmit,
    deleteModifierFromDB,
    loading,
  };
};
