import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Card,
  CardContent,
  Chip,
} from "@mui/material";

const MenuView = ({ menuData }: any) => {
  return (
    <Box sx={{ mt: 6, ml: 2, mr: 2 }}>
      {menuData.length === 0 ? (
        <Typography>No Menu Items Available</Typography>
      ) : (
        <List>
          {menuData.map((menuItem: any, index: number) => (
            <ListItem key={index} sx={{ mb: 2, width: "100%", padding: 0 }}>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      {menuItem.image && (
                        <Avatar
                          alt={typeof menuItem.name === "object" ? menuItem.name.en : menuItem.name}
                          src={menuItem.image}
                          sx={{ width: 56, height: 56 }}
                        />
                      )}
                      <ListItemText
                        primary={<Typography variant="h6">{typeof menuItem.name === "object" ? menuItem.name.en : menuItem.name}</Typography>}
                        secondary={menuItem.description || "No description available"}
                      />
                    </Box>
                    <Chip
                      label={`Price: ${menuItem.price ? `${menuItem.price}` : "N/A"}`}
                      color="secondary"
                      variant="outlined"
                    />
                    <Chip
                      label={menuItem.isActive ? "Active" : "Inactive"}
                      color={menuItem.isActive ? "success" : "error"}
                      variant="outlined"
                    />
                  </Box>
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default MenuView;
