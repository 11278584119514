import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { MdClose, MdFileUpload, MdEdit, MdDelete } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "components/card";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Snackbar,
  Alert,
} from "@mui/material";
import Select from "react-select";
import * as Yup from "yup";

import { BsFileEarmarkImage } from "react-icons/bs";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import ImageUploader from "components/imageUpload";
import { useMenu } from "../menu/useMenu";
import { MenuProps } from "./interface";

const Menu: React.FC<MenuProps> = ({ branchId }) => {
  const {
    rows,
    menuData,
    openModal,
    loading,
    editMode,
    image,
    initialValues,
    selectedMenu,
    successMessage,
    errorMessage,
    modifierOptions,
    categoryOptions,
    handleOpenModal,
    handleCloseModal,
    handleEditMenu,
    handleDeleteMenu,
    handleSubmit,
    handleToggleActive,
    handleResetImage,
    setSuccessMessage,
    setErrorMessage,
  } = useMenu(branchId);

  return (
    <div className="flex w-full flex-col gap-5">
      {menuData?.data?.length > 0 ? (
        <>
          <div className="flex justify-end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              className="mb-4"
            >
              Add New Menu
            </Button>
          </div>
          <Table className="w-full min-w-full table-auto">
            <Thead>
              <Tr>
                <Th className="border-b border-gray-100 px-5 py-3 text-left">
                  SI No.
                </Th>
                <Th className="border-b border-gray-100 px-5 py-3 text-left">
                  Name
                </Th>
                <Th className="border-b border-gray-100 px-5 py-3 text-left">
                  Image
                </Th>
                <Th className="border-b border-gray-100 px-5 py-3 text-left">
                  Price
                </Th>
                <Th className="border-b border-gray-100 px-5 py-3 text-left">
                  Is Active
                </Th>
                <Th className="border-b border-gray-100 px-5 py-3 text-center">
                  Actions
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {rows?.map((data: any) => (
                <Tr
                  key={data.id}
                  className="hover:cursor-pointer hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20"
                >
                  <Td className="border-b border-gray-100 px-5 py-3">
                    <p className="font-semibold">{data.siNo}</p>
                  </Td>
                  <Td className="border-b border-gray-100 px-5 py-3">
                    <p className="font-semibold">{data.name}</p>
                  </Td>
                  <Td className="border-b border-gray-100 px-5 py-3">
                    {data?.image?.data ? (
                      <img
                        src={data?.image.data.url}
                        alt={data?.name}
                        className="h-[50px] w-[50px] rounded-md object-cover"
                      />
                    ) : (
                      <BsFileEarmarkImage className="h-[40px] w-[40px] text-gray-800" />
                    )}
                  </Td>
                  <Td className="border-b border-gray-100 px-5 py-3">
                    <p className="font-semibold">${data.price}</p>
                  </Td>
                  <Td className="border-b border-gray-100 px-5 py-3">
                    <Switch
                      checked={data.isActive}
                      onChange={() =>
                        handleToggleActive(data.id, data.isActive)
                      }
                    />
                  </Td>
                  <Td className="border-b border-gray-100 px-5 py-3 text-center">
                    <div className="flex items-center gap-2 xl:justify-center">
                      <button
                        onClick={(event) => {
                          event.stopPropagation();
                          handleEditMenu(data);
                        }}
                        className="z-50 text-blue-500 hover:underline"
                      >
                        <MdEdit className="h-5 w-5" />
                      </button>
                      <button
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteMenu(data.id);
                        }}
                        className="z-50 text-red-500 hover:underline"
                      >
                        <MdDelete className="h-5 w-5" />
                      </button>
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      ) : (
        <Card className="flex flex-col items-center justify-center py-10">
          <p className="mb-5 text-gray-600">No menus available.</p>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenModal}
            className="mt-5"
          >
            Add New Menu
          </Button>
        </Card>
      )}

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{editMode ? "Edit Menu" : "Add New Menu"}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={selectedMenu || initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              price: Yup.number()
                .required("Price is required")
                .positive("Price must be positive"),
              description: Yup.string().nullable(),
              category: Yup.array()
                .of(Yup.string())
                .required("Category is required"),
              modifierIds: Yup.array().of(Yup.string()),
            })}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                {/* Name Field */}
                <div className="mb-3">
                  <label className="ml-3 text-sm font-bold text-navy-700">
                    Name*
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                {/* Price Field */}
                <div className="mb-3">
                  <label className="ml-3 text-sm font-bold text-navy-700">
                    Price*
                  </label>
                  <Field
                    type="number"
                    name="price"
                    className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                  />
                  <ErrorMessage
                    name="price"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                {/* Category Field */}
                <div className="mb-3">
                  <label className="ml-3 text-sm font-bold text-navy-700">
                    Category*
                  </label>
                  <Select
                    isMulti
                    options={categoryOptions}
                    value={values.category.map(
                      (catId) =>
                        categoryOptions.find(
                          (cat: any) => cat.value === catId
                        ) || {
                          value: "undefined",
                          label: "Unknown Category",
                        }
                    )}
                    placeholder="Select category"
                    name="category"
                    onChange={(selectedOptions) => {
                      const formattedCategories = selectedOptions.map(
                        (option) => option.value
                      );
                      setFieldValue("category", formattedCategories);
                    }}
                  />

                  <ErrorMessage
                    name="category"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                {/* Image Upload Field */}
                <div className="mb-3">
                  <label className="ml-3 text-sm font-bold text-navy-700">
                    Image
                  </label>
                  <div className="col-span-5 my-3">
                    <Card extra="w-full h-full p-3">
                      <div className="relative flex h-full w-full items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700">
                        <ImageUploader
                          setFieldValue={setFieldValue}
                          folder="/menu-images"
                          uploadButton={
                            <div className="flex h-full w-full flex-col items-center justify-center">
                              <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                              <h4 className="text-xl font-bold text-brand-500 dark:text-white">
                                Upload Image
                              </h4>
                              <p className="mt-2 text-sm font-medium text-gray-600">
                                Click to Upload Image
                              </p>
                            </div>
                          }
                          loadingIndicator={
                            <CircularProgress className="h-[80px] w-[80px]" />
                          }
                          imageDisplay={(image, resetImage) => (
                            <div className="relative h-[120px]">
                              <img
                                src={image.url}
                                alt={image.name}
                                className="h-[120px] w-[120px]"
                              />
                              <button
                                className="absolute right-1 top-1"
                                onClick={() =>
                                  handleResetImage(resetImage, setFieldValue)
                                }
                              >
                                <MdClose className="h-6 w-6" />
                              </button>
                            </div>
                          )}
                          initialImage={image}
                        />
                      </div>
                    </Card>
                  </div>
                </div>

                {/* Modifiers Multi-Select */}
                <div className="mb-3">
                  <label className="ml-3 text-sm font-bold text-navy-700">
                    Modifiers
                  </label>
                  <Select
                    isMulti
                    options={modifierOptions}
                    value={values.modifierIds.map(
                      (modId) =>
                        modifierOptions.find(
                          (mod: any) => mod.value === modId
                        ) || null
                    )}
                    placeholder="Select modifiers"
                    name="modifierIds"
                    onChange={(selectedOptions) => {
                      const formattedModifiers = selectedOptions.map(
                        (option) => option.value
                      );
                      setFieldValue("modifierIds", formattedModifiers);
                    }}
                  />
                </div>

                {/* Submit Button */}
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="mt-3"
                    disabled={loading}
                  >
                    {loading ? "Saving..." : editMode ? "Update" : "Add Menu"}{" "}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            color="secondary"
            disabled={loading}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={4000}
        onClose={() => setSuccessMessage(null)}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={4000}
        onClose={() => setErrorMessage(null)}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </div>
  );
};

export default Menu;
