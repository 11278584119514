/*  */
/* Maintenance services */
/*  */

import {getDBInstance, ObjectId } from "./dbServices";

export const updateAppSettings = async ({
    id,
    values,
    fullAndroidMaintenance,
    fullIOSMaintenance,
    maintenanceModeAndroid,
    maintenanceModeIOS,
    type,
  }: any) => {
    if (!id) {
      const result = await getDBInstance().collection("appSettings").insertOne({
        androidMinimumVersion: values?.androidVersion,
        iosMinimumVersion: values?.iOSVersion,
        fullAndroidMaintenance,
        fullIOSMaintenance,
        maintenanceModeAndroid,
        maintenanceModeIOS,
        type,
        dateCreated: new Date(),
        dateModified: null,
      });
  
      return result;
    }
  
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("appSettings")
      .updateOne(
        {
          _id: id,
        },
        {
          $set: {
            androidMinimumVersion: values?.androidVersion,
            iosMinimumVersion: values?.iOSVersion,
            fullAndroidMaintenance,
            fullIOSMaintenance,
            maintenanceModeAndroid,
            maintenanceModeIOS,
            type,
            dateModified: new Date(),
          },
        }
      );
  
    return result;
  };
  
  export const getAppSettings = async ({ type }: any) => {
  
    const result = await getDBInstance()
      .collection("appSettings")
      .aggregate([
        {
          $match: { type: type },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]);
  
    return result[0];
  };