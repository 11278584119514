import { useState, useEffect } from "react";
import {
  useMenuList,
  useCategoriesList,
  useModifiersList,
} from "model/queryCalls/queryCalls";
import {
  AddMenuValues,
  UpdateMenuValues,
  DeleteMenu,
  UpdateOneMenuStatus,
} from "controller/services/menuServices";
import { FormValues, UseMenuReturn } from "./interface";

export const useMenu = (branchId: string): UseMenuReturn => {
  const { data: menuData, refetch } = useMenuList({
    page: 0,
    rowsPerPage: 10,
    branchId,
  });
  const { data: categoriesData } = useCategoriesList({});
  const { data: modifiersData } = useModifiersList({ branchId });

  const [openModal, setOpenModal] = useState(false);
  const [image, setImage] = useState<{ name: string; url: string } | null>(
    null
  );

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<FormValues | null>(null);

  const initialValues: FormValues = {
    name: "",
    price: 0,
    description: "",
    modifierIds: [],
    category: [],
    image: "",
    isActive: true,
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setEditMode(false);
    setSelectedMenu(null);
    setImage(null);
  };

  useEffect(() => {
    if (editMode && selectedMenu) {
      if (selectedMenu?.image) {
        setImage({
          name: selectedMenu.name,
          url: selectedMenu?.image?.data?.url,
        });
      }
    }
  }, [editMode, selectedMenu]);

  const mapIdsToOptions = (
    ids: string[],
    options: { value: string; label: string }[]
  ) => {
    return ids
      .filter((id) => id !== null && id !== undefined)
      .map((id) => options.find((option) => option.value === id.toString()))
      .filter(Boolean);
  };

  const handleToggleActive = async (
    menuId: string,
    currentIsActive: boolean
  ) => {
    try {
      await UpdateOneMenuStatus({ id: menuId, isActive: !currentIsActive });
      refetch();
      setSuccessMessage("Menu status updated successfully!");
    } catch (error) {
      setErrorMessage("Failed to update menu status. Please try again.");
    }
  };

  const categoryOptions = categoriesData?.data?.map((category: any) => ({
    value: category._id?.toString() || "undefined-id",
    label: category.name || "Unnamed Category",
  }));

  const modifierOptions = modifiersData?.data?.map((modifier: any) => ({
    value: modifier._id.toString(),
    label: modifier.name,
  }));

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const menu = {
        ...values,
        branchId,
        image: values.image,
        modifierIds: values.modifierIds.map((mod) => mod),
        category: values.category.map((cat) => cat),
      };

      if (editMode && selectedMenu?._id) {
        await UpdateMenuValues({ data: { ...menu, id: selectedMenu._id } });
        setSuccessMessage("Menu updated successfully!");
      } else {
        await AddMenuValues({ data: menu });
        setSuccessMessage("Menu added successfully!");
      }

      refetch();
      handleCloseModal();
    } catch (error) {
      setErrorMessage("Failed to save menu. Please try again.");
    } finally {
      setLoading(false);
      resetFormState();
    }
  };

  const resetFormState = () => {
    setEditMode(false);
    setSelectedMenu(null);
    setImage(null);
  };

  const handleEditMenu = (menu: any) => {
    setEditMode(true);

    const mappedCategories = mapIdsToOptions(menu.category, categoryOptions);
    const mappedModifiers = mapIdsToOptions(menu.modifier, modifierOptions);

    setSelectedMenu({
      _id: menu.id,
      name: menu.name || "",
      price: menu.price || 0,
      description: menu.description || "",
      modifierIds: mappedModifiers.map((modId) => modId.value),
      category: mappedCategories.map((catId) => catId.value),
      image: menu?.image || "",
      isActive: menu.isActive,
    });
    handleOpenModal();
  };

  const handleDeleteMenu = async (menuId: string) => {
    try {
      await DeleteMenu({ id: menuId });
      refetch();
      setSuccessMessage("Menu deleted successfully!");
    } catch (error) {
      setErrorMessage("Failed to delete menu. Please try again.");
    }
  };

  const handleResetImage = (
    resetImageFn: () => void,
    setFieldValue: (field: string, value: any) => void
  ) => {
    resetImageFn();
    setImage(null);
    setFieldValue("image", null);
  };

  const rows = menuData?.data?.map((menu: any, index: number) => ({
    siNo: index + 1,
    id: menu._id,
    name: menu.name.en || "N/A",
    price: menu.price || 0,
    image: menu.image,
    isActive: menu.isActive,
    category: menu.category,
    modifier: menu.modifier,
  }));

  return {
    menuData,
    categoriesData,
    modifiersData,
    rows,
    initialValues,
    image,
    setImage,
    openModal,
    loading,
    editMode,
    selectedMenu,
    successMessage,
    errorMessage,
    modifierOptions,
    categoryOptions,
    handleOpenModal,
    handleCloseModal,
    handleEditMenu,
    handleDeleteMenu,
    handleSubmit,
    handleToggleActive,
    handleResetImage,
    setSuccessMessage,
    setErrorMessage,
  };
};
