import { app, getDBInstance, ObjectId } from "./dbServices";

/* Category services */

export const addCategory = async ({ data }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!data) {
    return;
  }

  const result = await getDBInstance()
    .collection("categories")
    .insertOne({
      name: data.name,
      description: data.description || "",
      image: data.image || null,
      isActive: data.isActive !== undefined ? data.isActive : true,
      createdAt: new Date(),
      createdBy: data.createdBy || app.currentUser.id,
      modifiedAt: null,
      modifiedBy: null,
    });
  return result;
};

export const updateCategory = async ({ id, data }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !data) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("categories")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          name: data.name,
          description: data.description || "",
          image: data.image || null,
          isActive: data.isActive !== undefined ? data.isActive : true,
          modifiedAt: new Date(),
          modifiedBy: data.modifiedBy || app.currentUser.id,
        },
      }
    );
  return result;
};

export const deleteCategory = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection("categories").deleteOne({
    _id: id,
  });
  return result;
};

export const getCategories = async ({ page = 0, rowsPerPage = 10 }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance()
    .collection("categories")
    .count({});

  const result = await getDBInstance()
    .collection("categories")
    .aggregate([{ $skip: skip }, { $limit: limit }]);

  return { data: result, totalDocumentCount };
};

export const getOneCategory = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection("categories").findOne({
    _id: id,
  });

  return result;
};

export const UpdateOneCategoryStatus = async ({ id, isActive}: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id ) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("categories")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          isActive:isActive,
        },
      }
    );
  return result;
}
