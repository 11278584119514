import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { updateUser } from "controller/services/userServices";
import { AlertContext } from "controller/context/alertContext";

export const useRoles = () => {
  const { id } = useParams();

  const { setAlert } = useContext(AlertContext);
  // add Users
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  // add Users

  //add Users
  const initialValues = {
    role: "",
  };

  const validationSchema = Yup.object().shape({
    role: Yup.string().required("Please enter your role"),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      if (id) {
        await updateUser({ id, name: values.name });
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "User Updated successfully",
        });
        setTimeout(() => {
          navigate("/admin/all-users");
          setLoading(false);
        }, 1000);
      } else {
        // await userRegister({ data: values });
        setAlert({
          active: true,
          type: "success",
          message: "User Created successfully",
        });
        setTimeout(() => {
          navigate("/admin/all-roles");
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      // loginError();
    }
  };

  return {
    //add user start
    loading,
    setLoading,
    initialValues,
    validationSchema,
    onSubmit,
  };
};
