import * as Realm from "realm-web";
import { createContext, useState, useEffect } from "react";
import { app, branchApp } from "../services/dbServices";

export const UserContext = createContext(null);
export function UserProvider({ children }: any) {
  const [user, setUser] = useState<any>();
  const [currentApp, setCurrentApp] = useState(null);
  const [customerData, setCustomerData] = useState();
  const [userType, setUserType] = useState("");
  const isVerified = JSON.parse(localStorage.getItem("isVerified") || "false");
  const emailPasswordLogin = async (
    email: string,
    password: string,
    userType: string
  ) => {
    const credentials = Realm.Credentials.emailPassword(email, password);
    let selectedApp;

    if (userType === "branch") {
      selectedApp = branchApp;
    } else {
      selectedApp = app;
    }

    setUserType(userType);
    try {
      const authUser = await selectedApp.logIn(credentials);
      setCurrentApp(selectedApp);
      setUser(authUser);
      return { authUser, selectedApp };
    } catch (error) {
      throw new Error("Login failed: " + error);
    }
  };

  useEffect(() => {
    if (!user) {
      if (app.currentUser) {
        setUser(app.currentUser);
        setCurrentApp(app);
      } else if (branchApp.currentUser) {
        setUser(branchApp.currentUser);
        setCurrentApp(branchApp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.currentUser, branchApp.currentUser]);

  const fetchUser = async () => {
    if (!app.currentUser) return false;
    try {
      await app.currentUser.refreshCustomData();
      setUser(app.currentUser);
      return app.currentUser;
    } catch (error) {
      throw error;
    }
  };

  const logOutUser = async () => {
    try {
      if (currentApp && currentApp.currentUser) {
        await currentApp.currentUser.logOut();
        localStorage.setItem("isVerified", JSON.stringify(false));
        setUser(null);
        setCurrentApp(null);
        return true;
      } else {
        console.error("No current app instance found for logout");
      }
    } catch (error) {
      console.error("Error during logout: ", error);
      throw error;
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        fetchUser,
        emailPasswordLogin,
        logOutUser,
        customerData,
        currentApp,
        setCustomerData,
        isVerified,
        userType,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
