// src/controller/services/modifierServices.ts
import { app, getDBInstance, ObjectId } from "./dbServices";

/* Modifier services */

export const addModifier = async ({ data }: any) => {
  try {
    if (!app || !app.currentUser) {
      console.error("MongoDB app or currentUser not found");
      return;
    }

    if (!data) {
      console.error("No data to insert");
      return;
    }

    const result = await getDBInstance()
      .collection("modifiers")
      .insertOne({
        name: data.name,
        price: data.price,
        description: data.description,
        branchId: data.branchId,
        isActive: data.isActive !== undefined ? data.isActive : true,
        createdAt: new Date(),
        createdBy: data.createdBy || app.currentUser.id,
        modifiedAt: null,
        modifiedBy: null,
      });

    return result;
  } catch (error) {
    console.error("Error adding modifier:", error);
  }
};

export const updateModifier = async ({ id, data }: any) => {
  try {
    if (!app || !app.currentUser) {
      console.error("MongoDB app or currentUser not found");
      return;
    }

    if (!id || !data) {
      console.error("Invalid modifier ID or data");
      return;
    }

    const objectId = new ObjectId(id);

    const result = await getDBInstance()
      .collection("modifiers")
      .updateOne(
        { _id: objectId },
        {
          $set: {
            name: data.name,
            description: data.description,
            price: data.price,
            modifiedAt: new Date(),
            modifiedBy: app.currentUser.id,
          },
        }
      );

    return result;
  } catch (error) {
    console.error("Error updating modifier:", error);
  }
};

export const deleteModifier = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection("modifiers").deleteOne({
    _id: id,
  });
  return result;
};

export const getModifiers = async ({
  page = 0,
  rowsPerPage = 10,
  branchId,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }

  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance()
    .collection("modifiers")
    .count({
      branchId: branchId,
    });

  const result = await getDBInstance()
    .collection("modifiers")
    .aggregate([
      {
        $match: {
          branchId: branchId,
        },
      },
      { $skip: skip },
      { $limit: limit },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOneModifier = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("modifiers")
    .aggregate([
      {
        $match: { _id: id },
      },
    ]);

  return result[0];
};
