import {
  Typography,
  Box,
  Skeleton,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { useRestaurant } from "../../useRestaurant";

interface WorkingHours {
  open: string;
  close: string;
}

const RestaurantDetails = ({ restaurantData, loading }: any) => {
  const { categoryOptions, cuisineOptions, mapIdsToOptions } = useRestaurant();
  if (loading) {
    return (
      <Box>
        <Skeleton sx={{ height: 190 }} />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
      </Box>
    );
  }

  if (!restaurantData) {
    return <Typography>No data available.</Typography>;
  }

  const { name, description, branchDetails, isActive } = restaurantData;
  const branch = branchDetails?.[0] || {};

  const mappedCategories = mapIdsToOptions(
    branch?.category || [],
    categoryOptions
  );
  const mappedCuisines = mapIdsToOptions(branch?.cuisine || [], cuisineOptions);

  return (
    <Card sx={{ m: 6, border: 1, borderColor: "divider" }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Restaurant Name: {name?.en || "N/A"}
            </Typography>
            <Typography variant="body1">
              Description: {description || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Type: {branch.type || "N/A"}
            </Typography>
            <Typography variant="body1">
              IsActive: {isActive || "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Address and Contact */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Address: {branch?.location?.address || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Contact: {branch?.contactDetails || "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Cuisine and Category */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Cuisine:{" "}
              {mappedCuisines.length > 0
                ? mappedCuisines.map((cuisine) => cuisine.label).join(", ")
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Category:{" "}
              {mappedCategories.length > 0
                ? mappedCategories.map((category) => category.label).join(", ")
                : "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Logo and Banner */}
          <Grid item xs={12} sm={6}>
            {branch.logo && (
              <Box>
                <Typography variant="body1">Logo</Typography>
                <img src={branch.logo} alt="Restaurant Logo"  className="h-[120px] w-[120px]" />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {branch.banner && (
              <Box>
                <Typography variant="body1">Banner</Typography>
                <img src={branch.banner} alt="Restaurant Banner"  className="h-[120px] w-[120px]" />
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Username and Password */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">Email</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={branch.username || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">Password</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={branch.password || ""}
              disabled
              type="password"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Working Hours and Payment Status */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">Working Hours</Typography>
            {branch?.workingHours ? (
              Object.entries(branch.workingHours).map(([day, hours]) => {
                const workingHour = hours as WorkingHours;
                return (
                  <Typography key={day} variant="body1">
                    {day.charAt(0).toUpperCase() + day.slice(1)}:{" "}
                    {workingHour.open && workingHour.close
                      ? `${workingHour.open} - ${workingHour.close}`
                      : "Closed"}
                  </Typography>
                );
              })
            ) : (
              <Typography variant="body1">
                No working hours available
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Payment Status: {branch.paymentStatus || "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RestaurantDetails;
