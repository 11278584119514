import { app, getDBInstance, ObjectId } from "./dbServices";

export const AddMenuValues = async ({ data }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!data) {
    return;
  }

  if (!data.branchId) {
    return;
  }

  // Insert a new menu item into the "menu" collection
  const result = await getDBInstance()
    .collection("menu")
    .insertOne({
      branchId: data.branchId,
      name: { en: data.name },
      price: data.price,
      category: data.category,
      description: data.description || "",
      modifier: data.modifierIds || [],
      image: data.image || null,
      isActive: true,
      createdAt: new Date(),
      createdBy: data.createdBy || app.currentUser.id,
      modifiedAt: null,
      modifiedBy: null,
    });

  return result;
};

export const GetMenuValues = async ({
  page = 0,
  rowsPerPage = 10,
  branchId,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  const skip = page * rowsPerPage;
  const limit = rowsPerPage;
  const totalDocumentCount = await getDBInstance().collection("menu").count({});

  const result = await getDBInstance()
    .collection("menu")
    .aggregate([{ $match: { branchId } }, { $skip: skip }, { $limit: limit }]);
  return { data: result, totalDocumentCount };
};

export const GetOneMenuValue = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!id) {
    return;
  }

  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection("menu").findOne({
    _id: id,
  });

  return result;
};

export const UpdateOneMenuStatus = async ({ id, isActive }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("menu")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          isActive: isActive,
        },
      }
    );
  return result;
};

export const UpdateMenuValues = async ({ data }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!data.id) {
    return;
  }

  if (typeof data.id === "string") {
    data.id = new ObjectId(data.id);
  }

  const result = await getDBInstance()
    .collection("menu")
    .updateOne(
      {
        _id: data.id,
      },
      {
        $set: {
          branchId: data.branchId,
          name: { en: data.name },
          price: data.price,
          category: data.category,
          description: data.description || "",
          modifier: data.modifierIds || [],
          image: data.image || null,
          isActive: true,
          modifiedAt: new Date(),
          modifiedBy: data.createdBy || app.currentUser.id,
        },
      }
    );
  return result;
};

export const DeleteMenu = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection("menu").deleteOne({
    _id: id,
  });
  return result;
};
