/*  */
/* Auth services */
/*  */

import { app, branchApp } from "./dbServices";
import * as Realm from "realm-web";

export const branchUserRegister = async ({ data }: any) => {
  if (!data) {
    throw new Error("No data provided for branch user registration.");
  }
  if (!branchApp.currentUser) {
    try {

      const credentials = Realm.Credentials.anonymous();
      await branchApp.logIn(credentials);
    } catch (error) {
      throw new Error(`Failed to log into branchApp: ${error}`);
    }
  }

  try {
    const result = await branchApp.currentUser.callFunction("register", {
      data,
    });
    return result;
  } catch (error) {
    throw new Error(`Failed to register branch user: ${error}`);
  }
};
export const userRegister = async ({ data }: any) => {
  if (!data) {
    return;
  }
  if (!app || !app.currentUser) {
    const credentials = Realm.Credentials.anonymous();
    await app.logIn(credentials);
  }
  const result = await app.currentUser.callFunction("register", {
    data,
  });

  return result;
};

export const sendEmailAfterLogin = async ({ toEmail,currentApp }: any) => {
  if (!toEmail) {
    return;
  }

  const result = await currentApp.currentUser.callFunction("reSendEmail", {
    toEmail,
  });
  return result;
};

export const verifyOTP = async ({ email, submittedOtp,currentApp }: any) => {
  if (!email || !submittedOtp) {
    return;
  }

  const result = await currentApp.currentUser.callFunction("verifyOTP", {
    email,
    submittedOtp,
  });
  return result;
};
