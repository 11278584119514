import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Chip,
} from "@mui/material";

const ModifierView = ({ modifiersData }: any) => {
  return (
    <Box sx={{ mt: 6, ml: 2 ,mr:2}}>
      {modifiersData.length === 0 ? (
        <Typography>No Modifiers Available</Typography>
      ) : (
        <List>
          {modifiersData.map((modifier: any, index: number) => (
            <ListItem key={index} sx={{ mb: 2, width: "100%", padding: 0 }}>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <ListItemText
                        primary={
                          <Typography variant="h6">{modifier.name}</Typography>
                        }
                        secondary={modifier.description || "No description"}
                      />
                    </Box>
                    <Chip
                      label={`Price: ${modifier.price || "N/A"}`}
                      color="secondary"
                      variant="outlined"
                    />
                  </Box>
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default ModifierView;
