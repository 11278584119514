import { app, getDBInstance, ObjectId } from "./dbServices";

export const AddRestaurantValues = async ({ data, location, address }: any) => {
  if (!data) {
    return;
  }
  const existingRestaurant = await getDBInstance()
    .collection("branch")
    .findOne({ username: data.username });

  if (existingRestaurant) {
    throw new Error("A restaurant with this email already exists.");
  }

  // try {
  //   await branchApp.emailPasswordAuth.registerUser({
  //     email: data.username,
  //     password: data.password,
  //   });
  //   console.log("Realm user created successfully for branch.");

  //   const registerResult = await branchUserRegister({
  //     data: data,
  //     currentApp: branchApp,
  //   });
  //   console.log("Additional user registration success:", registerResult);
  // } catch (error: any) {
  //   console.error("Failed to create Realm user:", error.message);
  //   if (error.message.includes("name already in use")) {
  //     throw new Error(
  //       "A user with this email already exists in the branch app."
  //     );
  //   } else {
  //     throw new Error(
  //       `Failed to create Realm user for branch: ${error.message}`
  //     );
  //   }
  // }
  // let prefix = "FIN";
  // let refNumber;

  // const createRefNumber = await app.currentUser.callFunction(
  //   "generateReference",
  //   "branch",
  //   prefix + "-",
  //   "refNumber"
  // );

  // if (createRefNumber) {
  //   refNumber = createRefNumber;
  // } else {
  //   refNumber = prefix + "-1";
  // }

  const result = await getDBInstance()
    .collection("restaurant")
    .insertOne({
      name: { en: data.name },
      description: data.description,
      isActive: data.isActive,
      createdAt: new Date(),
      createdBy: data.createdBy || app.currentUser.id,
      modifiedAt: null,
      modifiedBy: null,
    });

  const branchResult = await getDBInstance()
    .collection("branch")
    .insertOne({
      restaurantId: result.insertedId,
      // prefix: refNumber,
      workingHours: data.workingHours,
      type: data.type,
      category: data.category,
      contactDetails: data.contactDetails,
      username: data.username,
      password: data.password,
      paymentStatus: data.paymentStatus,
      cuisine: data.cuisine,
      logo: data.logo,
      banner: data.banner,
      location: {
        coordinates: location,
        address: address,
      },
      createdAt: new Date(),
      createdBy: data.createdBy || app.currentUser.id,
      modifiedAt: null,
      modifiedBy: null,
    });

  return branchResult;
};

export const getRestaurantValues = async ({
  page = 0,
  rowsPerPage = 10,
}: any) => {
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance()
    .collection("restaurant")
    .count({});

  const result = await getDBInstance()
    .collection("restaurant")
    .aggregate([
      {
        $match: {},
      },
      {
        $lookup: {
          from: "branch",
          localField: "_id",
          foreignField: "restaurantId",
          as: "branchDetails",
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
      { $skip: skip },
      { $limit: limit },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOneRestaurant = async ({ id }: any) => {
  try {
    const restaurantObjectId = new ObjectId(id);

    const result = await getDBInstance()
      .collection("restaurant")
      .aggregate([
        {
          $match: {
            _id: restaurantObjectId,
          },
        },
        {
          $lookup: {
            from: "branch",
            localField: "_id",
            foreignField: "restaurantId",
            as: "branchDetails",
          },
        },
        {
          $unwind: {
            path: "$branchDetails",
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $lookup: {
            from: "modifiers",
            localField: "branchDetails._id",
            foreignField: "branchId",
            as: "branchDetails.modifiers",
          },
        },
        {
          $lookup: {
            from: "menu",
            localField: "branchDetails._id",
            foreignField: "branchId",
            as: "branchDetails.menuItems",
          },
        },
        {
          $group: {
            _id: "$_id",
            name: { $first: "$name" },
            description: { $first: "$description" },
            createdAt: { $first: "$createdAt" },
            createdBy: { $first: "$createdBy" },
            modifiedAt: { $first: "$modifiedAt" },
            modifiedBy: { $first: "$modifiedBy" },
            branchDetails: { $push: "$branchDetails" },
          },
        },
      ]);
    return result.length ? result[0] : null;
  } catch (error) {
    console.error("Error fetching restaurant:", error);
    return null;
  }
};

// Delete restaurant
export const deleteRestaurant = async (restaurantId: string) => {
  const db = getDBInstance();
  const restaurantObjectId = new ObjectId(restaurantId);

  const restaurantDeleteResult = await db.collection("restaurant").deleteOne({
    _id: restaurantObjectId,
  });

  const branches = await db.collection("branch").find({
    restaurantId: restaurantObjectId,
  });

  const branchIds = branches.map((branch) => branch._id);

  const branchDeleteResult = await db.collection("branch").deleteMany({
    restaurantId: restaurantObjectId,
  });
  const modifierDeleteResult = await db.collection("modifiers").deleteMany({
    branchId: { $in: branchIds },
  });
  const menuDeleteResult = await db.collection("menu").deleteMany({
    branchId: { $in: branchIds },
  });

  return {
    restaurantDeleteResult,
    branchDeleteResult,
    modifierDeleteResult,
    menuDeleteResult,
  };
};

export const updateRestaurant = async ({
  restaurantId,
  data,
  location,
  address,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  try {
    const db = getDBInstance();
    const restaurantObjectId = new ObjectId(restaurantId);
    const restaurantUpdateResult = await db.collection("restaurant").updateOne(
      { _id: restaurantObjectId },
      {
        $set: {
          name: { en: data.name },
          description: data.description,
          modifiedAt: new Date(),
          modifiedBy: data.modifiedBy || app.currentUser.id,
        },
      }
    );

    const branchUpdateResult = await db.collection("branch").updateOne(
      { restaurantId: restaurantObjectId },
      {
        $set: {
          workingHours: data.workingHours,
          isActive: data.isActive,
          type: data.type,
          category: data.category,
          contactDetails: data.contactDetails,
          username: data.username,
          password: data.password,
          paymentStatus: data.paymentStatus,
          cuisine: data.cuisine,
          logo: data.logo,
          banner: data.banner,
          location: {
            coordinates: location,
            address: address,
          },
          modifiedAt: new Date(),
          modifiedBy: data.modifiedBy || app.currentUser.id,
        },
      }
    );

    const updatedBranch = await db
      .collection("branch")
      .findOne(
        { restaurantId: restaurantObjectId },
        { projection: { _id: 1 } }
      );

    return {
      restaurantUpdateResult,
      branchUpdateResult,
      branchId: updatedBranch?._id,
    };
  } catch (error) {
    console.error("Error updating restaurant:", error);
    return null;
  }
};

export const UpdateOneRestaurantStatus = async ({ id, isActive }: any) => {
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("restaurant")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          isActive: isActive,
        },
      }
    );
  return result;
};
